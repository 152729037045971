<template>
  <header class="bg-skin-header h-24 lg:h-32 absolute inset-0 z-50">
    <div class="mx-0 lg:mx-12 px-5 flex flex-col items-start justify-between gap-5 lg:flex-row lg:items-center">
        <div class="flex-none flex items-center justify-between w-full lg:w-auto">
            <div class="flex-none">
                <img src="https://ucarecdn.com/a942d6a8-202c-4ae8-8283-2e61f793d335/logo.webp" alt="Sushi Raku Logo" class="max-h-16" />
            </div>
            <h1 class="lg:ml-2 uppercase font-semibold text-4xl">Sushi <span class="text-skin-primary">Raku</span></h1>

            <div class="py-8 inline-flex flex-none lg:hidden">
                <button type="button" role="button" id="menu-toggle" class="text-skin-nav" :class="open && 'open'" @click="toggle()" aria-label="menu toggle">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
        </div>

        <nav class="border-t-2 lg:border-t-0 border-skin-primary w-full lg:w-auto bg-skin-header flex-none lg:block font-signika py-8 lg:py-12" id="menu-container" role="navigation" :class="!open && 'hidden'">
            <ul class="flex flex-col gap-y-5 uppercase text-lg font-bold lg:flex-row lg:gap-y-0 lg:gap-x-10">
                <li><a href="#home" class="text-skin-nav visited:text-skin-nav hover:text-skin-nav" @click="close()">Home</a></li>
                <li><a href="#about" class="text-skin-nav visited:text-skin-nav hover:text-skin-nav" @click="close()">About</a></li>
                <li><a href="#gallery" class="text-skin-nav visited:text-skin-nav hover:text-skin-nav" @click="close()">Gallery</a></li>
                <li><a href="#contact" class="text-skin-nav visited:text-skin-nav hover:text-skin-nav" @click="close()">Contact</a></li>
                <li class="block lg:hidden"><a href="https://foodex.menu/order/shop/sushi-raku-yxe/s/site" class="rounded border-2 border-skin-primary p-2 text-skin-primary visited:text-skin-primary hover:text-skin-primary">Order Online</a></li>
            </ul>
        </nav>
    </div>
  </header>

  <section class="relative overflow-hidden z-10" id="home">
      <div class="bg-skin-header h-screen relative z-10 flex items-center">
          <div class="container mx-auto px-5">
              <div class="grid lg:grid-cols-2 gap-10 place-items-center justify-center">
                  <div data-aos="fade-up" data-aos-duration="500" class="grid grid-cols-1 gap-12 place-items-center order-last lg:order-first">
                      <h2 class="text-3xl lg:text-6xl font-light text-center">A True Taste of<br/>Japanese Cuisine</h2>
                      <div class="flex flex-col space-y-4">
                        <a href="https://foodex.menu/order/shop/sushi-raku-yxe/s/site" class="rounded border-2 border-skin-primary p-2 text-skin-primary uppercase text-2xl text-center">See Menu</a>
                        <a href="https://foodex.menu/order/shop/sushi-raku-yxe/s/site" class="rounded border-2 border-skin-primary p-2 text-skin-primary uppercase text-2xl text-center">Order Online</a>
                      </div>
                      
                  </div>
                  <div data-aos="fade-left" data-aos-delay="100" data-aos-duration="700" class="w-2/3 lg:w-full">
                      <img src="https://ucarecdn.com/3ffdcff1-bba8-4c0b-a202-8572afa2c022/unagiroll.webp" />
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section class="py-20" id="about">
      <div class="container mx-auto px-5">
          <div class="grid lg:grid-cols-2 gap-10 place-items-center justify-center">
              <div class="w-2/3 lg:w-full relative">
                  <img data-aos="fade-right" data-aos-duration="700" class="relative z-10" src="https://ucarecdn.com/f1c0aa43-eeb4-48a4-bed4-a333e7c63c8f/chef_jason.webp" />
                  <div class="w-full lg:w-full h-full absolute -right-4 -bottom-4 lg:-right-8 lg:-bottom-8" :style="{ backgroundImage: 'url(https://ucarecdn.com/46614240-87f3-4d34-af3d-4e0d68a2e118/waves1.svg)' }"></div>
              </div>
              <div class="grid grid-cols-1 gap-8 place-items-center p-8 lg:p-16">
                  <h2 class="my-8 text-3xl lg:text-6xl">Our Story</h2>
                  <p>Sushi Raku has been passionately committed since our beginning to be the best sushi experience in Saskatoon, SK. Our approach to sushi pays homage to the artisanal beauty of traditional sushi, while also pushing the boundaries by combining sushi with other global cuisines and flavor profiles. The result is a unique experience that appeals to sushi fanatics and to those who have never tried sushi before. It has helped us win devoted fans who make Sushi Raku a part of their daily lives.</p>
                  <img src="https://ucarecdn.com/08fd9bbd-ec61-4d68-8dd8-9cfad9715bbb/signature.png" />
              </div>
          </div>
      </div>
  </section>

  <section class="py-20">
      <div class="container mx-auto px-5">
          <div class="grid lg:grid-cols-2 gap-10 place-items-center justify-center">
              <div class="grid grid-cols-1 gap-8 place-items-center p-8 lg:p-16 order-last lg:order-first">
                  <h2 class="my-8 text-3xl lg:text-6xl font-light">Always Fresh</h2>
                  <p>We procure all ingredients from the freshest sources possible. You can taste the difference.</p>
              </div>
              <div class="w-2/3 lg:w-full relative">
                  <img data-aos="fade-left" data-aos-duration="700" class="relative z-10" src="https://ucarecdn.com/f810dbcc-36fb-4f4d-93e1-339fead3daa5/sushi_sampler_diamond.webp" loading="lazy" />
                  <div class="w-36 lg:w-64 h-full absolute right-0 top-0" :style="{ backgroundImage: 'url(https://ucarecdn.com/3cc12eda-3218-48c8-a5e1-b6421064f33a/wavespatternred.webp)' }"></div>
              </div>
              
          </div>
      </div>
  </section>

  <section class="relative overflow-hidden" id="gallery">
      <div class="bg-skin-header py-16 lg:py-32 relative z-10">
          <div class="container mx-auto px-5">
              <div class="grid grid-col-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                  <img class="transition duration-500 ease-in-out md:transform hover:scale-110" src="https://ucarecdn.com/a55fc546-9336-4a46-9127-9d17031f1f78/-/preview/400x400/" alt="dinner party" loading="lazy" />
                  <img class="transition duration-500 ease-in-out md:transform hover:scale-110" src="https://ucarecdn.com/b1291655-a137-4501-8722-80c92c7a4462/-/preview/400x400/" alt="party tray" loading="lazy" />
                  <img class="transition duration-500 ease-in-out md:transform hover:scale-110" src="https://ucarecdn.com/10ffc421-8d54-4c49-be0d-c7af1b51c1fd/-/preview/400x400/" alt="salmon sashimi" loading="lazy" />
                  <img class="transition duration-500 ease-in-out md:transform hover:scale-110" src="https://ucarecdn.com/55690962-0e62-4c0e-a5ab-bf9eb542273d/-/preview/400x400/" alt="bbq tuna roll" loading="lazy" />
                  <img class="transition duration-500 ease-in-out md:transform hover:scale-110" src="https://ucarecdn.com/58d1eaf1-8315-4099-b05d-f6a97bc89ffd/-/preview/400x400/" alt="cold noodle" loading="lazy" />
                  <img class="transition duration-500 ease-in-out md:transform hover:scale-110" src="https://ucarecdn.com/315e0f4f-dc3e-40b6-a7e9-2126b8df354b/-/preview/400x400/" alt="ume wine" loading="lazy" />
                  <img class="transition duration-500 ease-in-out md:transform hover:scale-110" src="https://ucarecdn.com/539909ff-76a1-4dd3-8026-d7e1a01edb1b/-/preview/400x400/" alt="assorted nigiri sushi" loading="lazy" />
                  <img class="transition duration-500 ease-in-out md:transform hover:scale-110" src="https://ucarecdn.com/da27ea9c-233c-47cf-b081-d9b8ade7acaf/-/preview/400x400/" alt="aburi salmon" loading="lazy" />
                  <img class="transition duration-500 ease-in-out md:transform hover:scale-110" src="https://ucarecdn.com/b3bffcc6-b55f-4af2-9171-616ff1ba499c/-/preview/400x400/" alt="bento box" loading="lazy" />
              </div>
          </div>
      </div>
  </section>

  <section class="py-16 lg:py-32 relative" id="contact">
      
      <img class="absolute inset-0 opacity-5 my-20 lg:my-auto z-10" src="https://ucarecdn.com/f8ffde20-e403-484c-b940-2b6662659507/sushitray.webp" />
      <div class="container mx-auto px-5 relative z-50">
          <div class="grid grid-cols-1 lg:grid-cols-3">
              <div class="space-y-8 !font-bold text-xl mb-12">
                  
                  <div class="flex gap-4">
                      <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                      <div>
                          239 Idylwyld Dr S, <br /> Saskatoon, SK
                      </div>
                  </div>

                  <div class="flex gap-4">
                      <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
                      <a href="tel:+13062441100" class="hover:text-opacity-75">306-244-1100</a>
                  </div>

                  <div class="flex gap-4">
                      <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                      <a href="mailto:sushirakucanada@gmail.com" class="hover:text-opacity-75">sushirakucanada@gmail.com</a>
                  </div>

                  <div class="flex gap-4">
                      <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                      <div class="space-y-4">
                          <div>Open Seven Days</div>
                          <div>11:30 AM - 3:00 PM</div>
                          <div>4:30 PM - 9:00 PM</div>
                      </div>
                  </div>
              </div>
              
              <div class="col-span-2">
                  <div class="w-full h-144 embedded-map">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2449.416164482074!2d-106.67275108420716!3d52.126750879741685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5304f7aacccd2cbb%3A0x2cd8fa92f1284400!2sSushi%20Raku!5e0!3m2!1sen!2sca!4v1628666016796!5m2!1sen!2sca" class="w-full h-full" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <footer class="py-40 bg-gray-800" id="about">
      <div class="container mx-auto px-5">
          <div data-aos="fade-up" data-aos-duration="700" class="grid lg:grid-cols-2 gap-10 place-items-center justify-center">
              <div class="text-white">
                  <h2 class="text-2xl lg:text-5xl">Are You Ready To Get Rolling?</h2>
              </div>
              <div class="w-full text-center">
                  <a href="https://foodex.menu/order/shop/sushi-raku-yxe/s/site" class="rounded border-2 border-skin-primary p-4 tracking-wider text-white text-xl lg:text-3xl uppercase">Order Online</a>
              </div>
          </div>
      </div>
  </footer>
  <section class="p-4 opacity-50 text-center text-xs sm:text-sm">
      Copyright @2021 Sushi Raku. All rights reserved.
  </section>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'App',
  setup() {
    const open = ref(false);
    const toggle = () => { open.value = !open.value; }

    const close = () => {
        if (open.value)
        {
            open.value = false;
        }
    }
    return {
        open,
        toggle,
        close,
    }
  }
}
</script>
